import React from 'react';

const plans = {
  basicWebsites: [
    {
      name: 'Silver',
      price: 8000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 8000 },
        { period: 'period-year', price: 1200 },
      ],
      features: [
        'Welcome Page',
        'Responsive Design',
        'Basic SEO',
        'Domain Name Fee: 1000 EGP',
        'Taxes: 500 EGP',
      ],
    },
    {
      name: 'Gold',
      price: 14000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 14000 },
        { period: 'period-year', price: 1300 },
      ],
      features: [
        'Home, About, Contact Pages',
        'Responsive Design',
        'Basic SEO',
        'Custom Design',
        'Domain Name Fee: 1000 EGP',
        'Taxes: 500 EGP',
      ],
    },
    {
      name: 'Premium',
      price: 22000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 22000 },
        { period: 'period-year', price: 1350 },
      ],
      features: [
        'Home, About, Contact Pages',
        'Terms & Conditions and Privacy Policy Pages',
        'Responsive Design',
        'Advanced SEO',
        'Custom Design',
        'Domain Name Fee: 1000 EGP',
        'Taxes: 500 EGP',
      ],
    },
  ],
  eCommerceWebsites: [
    {
      name: 'Silver',
      price: 11000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 24000 },
        { period: 'period half-year', price: 3000 },
      ],
      features: [
        'Lite E-commerce with WhatsApp Order',
        'Responsive Design',
        'Basic SEO',
        'Custom Design',
        'Domain Name Fee: 1000 EGP',
        'Taxes: 500 EGP',
      ],
    },
    {
      name: 'Gold',
      price: 15000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 36000 },
        { period: 'period Half-year', price: 5800 },
      ],
      features: [
        'E-commerce with Dashboard',
        'Product Management via WhatsApp',
        'Visitor Analytics',
        'Customer Profiles',
        'Basket and Order Management',
        'Domain Name Fee: 1000 EGP',
        'Taxes: 500 EGP',
      ],
    },
    {
      name: 'Premium',
      price: 40000,
      per: 'year',
      additionalPrices: [
        { period: 'fixed', price: 63000 },
        { period: 'period Half-year', price: 8200 },
      ],
      features: [
        'Full E-commerce with Payment Integration (My Fatoorah)',
        'Advanced Analytics',
        'Order Tracking and Management',
        'Customer Profiles and Basket Functionality',
        'Responsive Design',
        'Advanced SEO',
        'Domain Name Fee: free',
        'Taxes: 0 EGP',
        'Startup Price: 10000 EGP',
      ],
    },
  ],
};

const PricingComponent: React.FC = () => {
  const handleWhatsAppClick = (planName: string) => {
    const message = `Hello, I'm interested in the ${planName} plan. Could you provide more details?`;
    const phoneNumber = '201208136809'; // Your WhatsApp number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 font-Custom2">
      {/* Basic Websites Section */}
      <div>
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center ">Basic Websites</h2>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-6 sm:gap-10 font-['Poppins']">
          {plans.basicWebsites.map((plan, index) => (
            <div key={index} className="relative bg-blue-600 w-full sm:w-[294px] h-auto rounded-3xl p-6">
              <div className="text-white text-3xl sm:text-5xl font-normal">
                {plan.additionalPrices.map((price, idx) => (
                  <div key={idx} className="text-2xl font-bold ">
                    {price.period}: {price.price} EGP
                  </div>
                ))}
              </div>
              <div className="opacity-50 text-white text-sm font-bold mt-2">
                {plan.name}
              </div>
              <div className="mt-4 sm:mt-6 space-y-2 sm:space-y-4">
                {plan.features.map((feature, idx) => (
                  <div key={idx} className="text-white text-sm">
                    {feature}
                  </div>
                ))}
              </div>
              <div className="mt-4 sm:mt-6">
                <button
                  onClick={() => handleWhatsAppClick(plan.name)}
                  className="bg-white text-blue-600 w-full h-10 sm:h-12 rounded-lg font-bold"
                >
                  Choose plan
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* E-commerce Websites Section */}
      <div className="mt-10 sm:mt-12">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">E-commerce Websites</h2>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-6 sm:gap-10 font-['Poppins']">
          {plans.eCommerceWebsites.map((plan, index) => (
            <div
              key={index}
              className={`relative border-2 ${plan.name === 'Gold' && 'bg-[#515DEF]'} w-full sm:w-[294px] h-auto rounded-3xl p-6 `}
            >
              <div className="text-white text-3xl sm:text-5xl font-normal">
                {plan.additionalPrices.map((price, idx) => (
                  <div key={idx} className="text-2xl font-bold">
                    {price.period}: {price.price} EGP
                  </div>
                ))}
              </div>
              <div className="opacity-50 text-white text-sm font-bold mt-2">
                {plan.name}
              </div>
              <div className="mt-4 sm:mt-6 space-y-2 sm:space-y-4">
                {plan.features.map((feature, idx) => (
                  <div key={idx} className="text-white text-sm">
                    {feature}
                  </div>
                ))}
              </div>
              {plan.name === 'Premium' && (
                <div className="mt-4 text-sm text-yellow-300 font-bold">
                  Domain name is free
                </div>
              )}
              <div className="mt-4 sm:mt-6">
                <button
                  onClick={() => handleWhatsAppClick(plan.name)}
                  className="bg-white text-green-600 w-full h-10 sm:h-12 rounded-lg font-bold"
                >
                  Choose plan
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
