import React from 'react';
import './BarNames.css'; // Make sure to include custom styles in this CSS file

const BarNames: React.FC = () => {
  const items = [
    { text: "Join The Waitlist", stroke: true },
    { text: "Join The Waitlist", stroke: false },
    { text: "Join The Waitlist", stroke: true },
    { text: "Join The Waitlist", stroke: false },
    { text: "Join The Waitlist", stroke: true },
    { text: "Join The Waitlist", stroke: false },
  ];

  return (
    <div className="bg-[#6a65ff] py-6 px-4 md:px-8 lg:px-20 flex items-center gap-6 max-h-[100px] overflow-x-hidden overflow-y-hidden max-w-[100vw]">
      {items.map((item, index) => (
        <div
          key={index}
          className={`flex-shrink-0 text-center text-[24px] md:text-[32px] font-normal font-['Luckiest Guy'] leading-[36px] md:leading-[48px] ${
            item.stroke ? 'stroke-text' : 'fill-text'
          }`}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default BarNames;
