// src/theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E3A8A', // darkBlue
    },
    secondary: {
      main: '#064E3B', // darkGreen
    },
    background: {
      default: '#1E3A8A', // background color
      paper: '#FFFFFF',  // white color for paper elements
    },
    text: {
      primary: '#FFFFFF', // white text
    },
  },
});

export default theme;
