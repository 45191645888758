import React, { useState } from 'react';
import { sendEmail } from '../utilies/sendEmail';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Import icons from react-icons

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsSuccess(false);
    setErrorMessage(null);

    try {
      await sendEmail(formData);
      setIsSuccess(true);
      setFormData({ fullName: '', phone: '', email: '', message: '' });
    } catch (error) {
      setErrorMessage('Failed to send the message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="py-8 md:py-24 px-6 bg-secondary text-white">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:min-h-[50vh]">
        {/* Contact Information */}
        <div>
          <h6 className="font-poppins uppercase text-sm">Contact Now</h6>
          <h3 className="font-luckiest-guy text-4xl my-2">GET IN TOUCH NOW</h3>
          <p className="font-primary mb-8">
            Lorem ipsum dolor sit amet, adipiscing elit. In hac habitasse platea dictumst. Duis porta, quam ut finibus ultrices.
          </p>

          <div>
            <h4 className="font-primary uppercase flex items-center gap-2">
              <FaPhoneAlt /> Phone
            </h4>
            {/* Make phone clickable */}
            <a href="tel:+201208136809" className="font-secondary mb-5 block hover:underline flex items-center gap-2">
              <FaPhoneAlt /> +20 120 813 6809
            </a>

            <h4 className="font-primary uppercase flex items-center gap-2">
              <FaEnvelope /> Email
            </h4>
            {/* Make email clickable */}
            <a href="mailto:support@codeocean.tech" className="font-secondary mb-5 block hover:underline flex items-center gap-2">
              <FaEnvelope /> support@codeocean.tech
            </a>

            <h4 className="font-primary uppercase flex items-center gap-2">
              <FaMapMarkerAlt /> Address
            </h4>
            <p className="font-secondary flex items-center gap-2">
              <FaMapMarkerAlt /> Alexandria Cairo Agriculture Road, nearly to Alex
            </p>
          </div>
        </div>

        {/* Contact Form */}
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="fullName"
              placeholder="Your Name"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full mb-5 p-4 bg-darkGreen text-white rounded-lg outline-none"
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              className="w-full mb-5 p-4 bg-darkGreen text-white rounded-lg outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full mb-5 p-4 bg-darkGreen text-white rounded-lg outline-none"
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              rows={6}
              className="w-full mb-5 p-4 bg-darkGreen text-white rounded-lg outline-none"
            />

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-4 bg-primary text-secondary rounded-full uppercase font-secondary hover:bg-darkGreen"
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>

          {isSuccess && (
            <p className="text-green-500 mt-5">
              Thank you for sending us a message! We will get back to you soon.
            </p>
          )}
          {errorMessage && (
            <p className="text-red-500 mt-5">
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
