import React, { useState } from 'react';
import { MdMenu, MdClose } from 'react-icons/md';
import logo from '../assets/images/logo.png';

const routes = [
  { name: 'Home', id: 'home' },
  { name: 'Features', id: 'why-section' },
  { name: 'Contact', id: 'contact' },
  { name: 'Pricing', id: 'logo-area-two' },
  { name: 'About', id: 'services-section' },
];

const ResponsiveNavbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Close sidebar on navigation
  };

  return (
    <header className="bg-black/40 backdrop-blur-md ml-6 text-white py-4 px-6 md:px-8 lg:px-12 w-[96vw] mt-5 rounded-xl">
      <div className="container mx-auto flex items-center justify-between gap-9">
        {/* Logo */}
        <div className="flex items-center">
          <img src={logo} alt="CodeOcean Logo" className="h-10" />
        </div>

        {/* Mobile Menu Button */}
        <button
          className="block md:hidden text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <MdClose className="h-6 w-6" /> : <MdMenu className="h-6 w-6" />}
        </button>

        {/* Desktop Navbar Links */}
        <nav className={`md:flex md:items-center md:space-x-6 ${isOpen ? 'block' : 'hidden'}`}>
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 gap-5">
            {routes.map((route) => (
              <li key={route.id}>
                <button
                  className="text-lg font-normal hover:text-gray-300"
                  onClick={() => handleScroll(route.id)}
                >
                  {route.name}
                </button>
              </li>
            ))}
            <li>
              <button
                className="px-6 py-2 bg-[#6a65ff] rounded-lg hover:bg-[#5a54e1] text-white"
                onClick={() => handleScroll('contact')}
              >
                Join The Waitlist
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Mobile Sidebar */}
      {isOpen && (
        <div className="fixed inset-0 bg-black/60 z-40 md:hidden">
          <div className="fixed inset-y-0 left-0 w-64 bg-black p-6 transform transition-transform duration-300 ease-in-out translate-x-0">
            <button
              className="absolute top-4 right-4 text-white"
              onClick={() => setIsOpen(false)}
            >
              <MdClose className="h-6 w-6" />
            </button>
            <ul className="flex flex-col space-y-4 mt-10">
              {routes.map((route) => (
                <li key={route.id}>
                  <button
                    className="text-lg font-normal text-white"
                    onClick={() => handleScroll(route.id)}
                  >
                    {route.name}
                  </button>
                </li>
              ))}
              <li>
                <button
                  className="px-6 py-2 bg-[#6a65ff] rounded-lg hover:bg-[#5a54e1] text-white"
                  onClick={() => handleScroll('contact')}
                >
                  Join The Waitlist
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default ResponsiveNavbar;
