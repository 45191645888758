import React, { useEffect, useState } from 'react';
import './WelcomeSection.css'; // Make sure to create this CSS file for animations
import newStudioImage from "../assets/images/bg_image.jpg"; // Ensure the image name matches

const WelcomeSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger fade-in animation after component mounts
    setIsVisible(true);
  }, []);

  // Your WhatsApp number and custom message
  const whatsappNumber = "1234567890"; // Replace with your number
  const customMessageJoin = "Hi, I want to join the waitlist!";
  const customMessageMore = "Hi, I would like to know more about your services.";

  return (
    <div className={`py-6 px-4 sm:py-12 sm:px-6 lg:py-16 lg:px-8 flex flex-col justify-center items-center gap-6 ${isVisible ? 'fade-in' : ''}`}>
      {/* Welcome Message */}
      <div className="text-center text-white text-3xl sm:text-4xl lg:text-5xl font-['Luckiest Guy'] capitalize">
        Welcome to Code Ocean Tech - Your Partner in Digital Success ⭐️
      </div>
      
      {/* Description */}
      <div className="text-center text-[#ebebeb] text-base sm:text-lg lg:text-xl font-normal font-['Poppins'] max-w-4xl mx-auto">
        At Code Ocean Tech, we create unique websites, e-commerce platforms, and brand identities with expert design and SEO strategies.
      </div>

      {/* Image with full rounded corners */}
      <div className="w-[300px] h-[300px] sm:w-[400px] sm:h-[400px] bg-gray-200 rounded-full overflow-hidden">
        <img
          src={newStudioImage}
          alt="Studio"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Buttons */}
      <div className="flex gap-4 max-sm:1">
        <a
          href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(customMessageJoin)}`}
          className="h-12 px-8 py-2 max-sm:px-4 bg-[#6a65ff] hover:bg-[#5a54e1] rounded-lg text-white font-['Luckiest Guy'] text-base leading-loose"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join The Waitlist
        </a>
        <a
          href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(customMessageMore)}`}
          className="h-12 px-8 py-2 border border-white rounded-lg text-white font-['Luckiest Guy'] text-base leading-loose"
          target="_blank"
          rel="noopener noreferrer"
        >
          Know More
        </a>
      </div>
      
    </div>
  );
};

export default WelcomeSection;
