import emailjs from 'emailjs-com';
import { marked } from 'marked';

interface EmailConfig {
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

export async function sendEmail({ fullName, email, phone, message }: EmailConfig): Promise<void> {
    const messageHtml = marked(`
        Full Name: ${fullName}
        
        Email: ${email}
        
        Phone Number:  ${phone}
        
        Message:
        
        ${message}
      `);
  try {
    const templateParams = {
      from_name: fullName,
      from_email: email,
      phone_number: phone,
      message: messageHtml,
    };

    const response = await emailjs.send(
      'service_9pzfvjh', // Your EmailJS Service ID
      'template_451nwic', // Your EmailJS Template ID
      templateParams,
      '7HL4eVp-o2gjdycLd' // Your EmailJS Public Key
    );

    if (response.status !== 200) {
      throw new Error('Failed to send email');
    }

    console.log('Email sent successfully!');
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
}
