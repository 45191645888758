import React from 'react';
import LogoPartners from '../assets/images/LogoPartners.png';

const AssistantAndPartners: React.FC = () => {
  return (
    <div className="relative w-full h-auto flex justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-60 mx-auto bg-[#0a0a0a37] overflow-hidden py-16 md:py-24 lg:py-32">
      {/* Background Blur Elements */}
      <div className="absolute bg-[#9c08ff] rounded-full blur-[200px] w-[300px] h-[300px] right-[-10%] top-[40%] md:right-[20%] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px]" />
      <div className="absolute bg-[#1e50ff] rounded-full blur-[200px] w-[200px] h-[200px] left-[-10%] top-[-10%] md:left-[10%] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px]" />
      
      {/* Central Content */}
      <div className="flex flex-col justify-center items-center z-10 text-center text-white max-w-full">
        <div className="text-3xl md:text-5xl font-['Luckiest Guy'] max-w-[90vw] md:max-w-[70vw] lg:max-w-[50vw]">
          <span>Useful </span>
          <span>software </span>
          <span>that We assist.</span>
        </div>
        <div className="flex flex-col items-center gap-4 mt-8">
          <img className="w-[90vw] sm:w-[80vw] md:w-[60vw] lg:w-[50vw] h-auto" src={LogoPartners} alt="Software Logos" />
        </div>
      </div>
      
      {/* Small White Blurs */}
      <div className="absolute w-3 h-3 bg-white rounded-full blur-[20px] left-[20%] top-[30%] md:left-[15%] lg:w-5 lg:h-5 lg:blur-[30px]" />
      <div className="absolute w-4 h-4 bg-white rounded-full blur-[20px] right-[25%] top-[20%] md:right-[35%] lg:w-6 lg:h-6 lg:blur-[30px]" />
    </div>
  );
};

export default AssistantAndPartners;
