import React from 'react';
import CODEOCEAN from "../assets/images/codeocean.jpg"; // Ensure the image name matches
import icon from "../assets/images/Icon.png"; // Ensure the image name matches
import icon1 from "../assets/images/Icon (1).png"; // Ensure the image name matches
import icon2 from "../assets/images/Icon (2).png"; // Ensure the image name matches
import icon3 from "../assets/images/Icon (3).png"; // Ensure the image name matches
import icon4 from "../assets/images/Icon (4).png"; // Ensure the image name matches
import icon5 from "../assets/images/Icon (5).png"; // Ensure the image name matches

// Define the interface for the section items
interface SectionItem {
  iconSrc: string;
  title: string;
  description: string;
}

const sectionItems: SectionItem[] = [
  {
    iconSrc: icon,
    title: 'Fully Responsive',
    description: 'Fully responsive design adapts seamlessly to all devices and screen sizes.',
  },
  {
    iconSrc: icon1,
    title: 'Super Support',
    description: 'Reliable assistance to resolve issues and enhance your experience.',
  },
  {
    iconSrc: icon2,
    title: 'Multiple Layouts',
    description: 'Multiple layouts offer flexibility, allowing you to choose and customize different designs for various needs.',
  },
  {
    iconSrc: icon5,
    title: 'Rich Documentation',
    description: 'Rich documentation offers comprehensive guidance and detailed information to help you effectively utilize and customize your tools.',
  },
  {
    iconSrc: icon4,
    title: 'Faster Loading',
    description: 'Faster loading ensures a smooth user experience with quick, efficient page transitions.',
  },
  {
    iconSrc: icon3,
    title: 'Lifetime Updates',
    description: 'Lifetime updates ensure ongoing improvements and new features, keeping your system current and effective over time.',
  },
];

const WhySection: React.FC = () => {
  return (
    <div className="relative w-screen  mx-auto p-4 lg:p-0">
      <div className="relative w-full h-auto">
        {/* Title */}
        <div className="text-center text-white text-4xl font-normal font-['Luckiest Guy'] leading-[70px] mb-8 lg:mb-12 max-w-[500px] mx-auto">
          Why CODE OCEAN TECH would be your best fit?
        </div>
        
        {/* Image */}
        <div className='px-44'>
{/* 
        <img
          className="w-full h-full max-h-[60vh]  object-fill rounded-[30px] shadow-white/25 mb-12 lg:mb-0 shadow-2xl"
          src={CODEOCEAN}
          alt="Why CODE OCEAN TECH"
        /> */}
        </div>
      </div>

      {/* Section Items */}
      <div className="flex flex-wrap justify-center gap-[156px] mt-10 lg:mt-12 font-['Poppins']">
        {sectionItems.map((item, index) => (
          <div
            key={index}
            className="w-full max-w-[287.22px] flex flex-col items-center gap-4 mb-8 lg:mb-0"
          >
            <div className="relative w-[80.56px] h-[75.90px]">
              {item.iconSrc && (
                <img
                  className="absolute w-full h-auto"
                  src={item.iconSrc}
                  alt={item.title}
                />
              )}
            </div>
            <div className="text-center text-white text-2xl font-normal font-['Luckiest Guy']">
              {item.title}
            </div>
            <div className="text-center text-white/70 text-base font-semibold font-['Open Sans'] leading-7">
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhySection;
