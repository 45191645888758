import React from 'react';
import newStudioImage from "../assets/images/studio.png"; // Ensure the image name matches

// Define types for section data
interface Section {
  title: string;
  description: string;
}

const sectionData: Section[] = [
  {
    title: "Various Categories",
    description: "We excel in diverse categories, offering tailored solutions for every aspect of your business's digital needs.",
  },
  {
    title: "Hired By Top Company",
    description: "Trusted by leading companies, we deliver expert solutions that elevate your business to new heights.",
  },
  {
    title: "Trusted and Accurate",
    description: "We provide clients with precise, trustworthy business analysis, ensuring informed decisions and strategies that drive success.",
  },
];

// Component for rendering section titles
const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <div className="text-white text-xl font-normal font-['Luckiest Guy'] leading-[25px] mb-4">
    {title}
  </div>
);

// Component for rendering section descriptions
const SectionDescription: React.FC<{ description: string }> = ({ description }) => (
  <div className="text-white/75 text-base font-normal font-['Poppins'] leading-relaxed tracking-tight mb-2 max-w-[35vw]  max-sm:max-w-[86vw] ">
    {description}
  </div>
);

// Component for rendering circles
const Circle: React.FC<{ style: React.CSSProperties }> = ({ style }) => (
  <div className="w-[4.90px] h-[5.22px] bg-white rounded-full absolute" style={style} />
);

// Component for rendering the button
const Button: React.FC = () => (
  <div className="flex justify-center mt-6 lg:mt-12 absolute bottom-24 right-0 max-sm:bottom-0 max-sm:left-0 max-sm:">
    <div className="p-5 bg-white rounded-[13px] shadow flex flex-col items-center justify-center">
      <div className="w-[281.59px] h-[52.81px] bg-[#236bfe] rounded-[13px] flex items-center justify-center">
        <span className="text-white text-sm font-medium font-['Poppins'] leading-tight">
          Analysis Now
        </span>
      </div>
    </div>
  </div>
);

const ServicesSection: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full mt-12 relative">
      {/* Left Container */}
      <div className="relative flex-1 lg:w-1/2">
        {/* Grid of circles */}
        <div className="absolute inset-0">
          {Array.from({ length: 8 }, (_, rowIndex) =>
            Array.from({ length: 4 }, (_, colIndex) => (
              <Circle
                key={`circle-${rowIndex}-${colIndex}`}
                style={{
                  left: `${colIndex * 29.39}px`,
                  top: `${rowIndex * 31.32}px`,
                }}
              />
            ))
          )}
        </div>
        {/* Image */}
        <img
          className="w-full h-auto max-w-md mx-auto rounded-[15px]"
          src={newStudioImage}
          alt="Studio"
        />
        
        {/* Button */}
        <Button />
      </div>

      {/* Right Container */}
      <div className="flex-1 lg:w-1/2 flex flex-col justify-between p-6 lg:p-12 ">
        {/* Main Title */}
        <div className="text-white text-[44px] font-normal font-['Luckiest Guy'] capitalize leading-[57.20px] tracking-wide mb-6 lg:mb-12">
          help you find the best analysis for your business
        </div>

        {/* Sections */}
        <div className="max-sm:w-full ">
          {sectionData.map((section, index) => (
            <div key={index} className='flex flex-row-reverse gap-3 max-sm:gap-2 '>
              <div>

              <SectionTitle title={section.title} />
              <SectionDescription description={section.description} />
              </div>
              <div className="w-[72.09px] h-[76.82px] bg-[#6a65ff] rounded-lg flex justify-center items-center">
                <div className="w-[40.55px] h-[43.21px] bg-white rounded-full" />
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default ServicesSection;
