import React from 'react';

const PricingPlans: React.FC = () => {
  const handleWhatsAppClick = (planType: string) => {
    const message = `Hello, I'm interested in your ${planType} pricing plan!`;
    const phoneNumber = "201208136809"; // Your WhatsApp number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="max-w-[80vw] h-[246.75px] relative">
      <div className="w-full h-[134.01px]">
        <div className="text-center text-white text-6xl font-normal font-custom leading-[64px]">
          Pricing Plans
        </div>
        <div className=" mx-auto mt-4 text-center text-white/75 text-base font-medium font-secondary leading-relaxed">
        Our plans are clear and transparent. Contact us to customize your plan according to your needs.
        </div>
      </div>
      <div className="w-[233.99px] h-[44.67px] absolute left-1/2 transform -translate-x-1/2 top-[202.08px]">
        <div className="relative">
          <div className="bg-white rounded-lg border border-[#e9ecf2] absolute w-full h-full" />
          <div className="bg-[#515def] rounded-tl-lg rounded-bl-lg absolute w-[119.12px] h-full" />
          <div className="absolute w-full flex justify-between items-center h-full px-4">
            <div
              className="text-center text-white text-sm font-bold font-secondary cursor-pointer"
              onClick={() => handleWhatsAppClick('Monthly')}
            >
              Monthly
            </div>
            <div
              className="text-center text-[#808d9e] text-sm font-bold font-secondary cursor-pointer"
              onClick={() => handleWhatsAppClick('Yearly')}
            >
              Yearly
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
