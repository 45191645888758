import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import WelcomeSection from "./components/WelcomeSection";
import ServicesSection from "./components/ServicesSection";
import ContactSection from "./components/ContactSection";
import NavbarBlur from "./components/Header";
import PricingBox from "./components/LogoAreaTwo";
import { pricingData } from "./utilies/pricingData";
import Footer from "./components/Footer";
import BarNames from "./utilies/BarNames";
import WhySection from "./components/WhySection";
import AssistantAndPartners from "./components/AssistantAndPartners";
import PeopleSays from "./components/PeopleSays";
import PricingComponent from "./components/LogoAreaTwo";
import PricingPlans from "./utilies/PricingPlans";

const App: React.FC = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [welcomeRef, welcomeInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [servicesRef, servicesInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [whyRef, whyInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [peopleSaysRef, peopleSaysInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [contactRef, contactInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <HelmetProvider>
      <Helmet>
        <title>CodeOcean - Your Digital Solutions Partner</title>
        <meta
          name="description"
          content="We create websites, e-commerce platforms, and customized systems for our clients."
        />
        <meta
          name="keywords"
          content="web development, e-commerce, system customization, CodeOcean"
        />
      </Helmet>
      <div className="bg-darkBlue text-white font-custom flex flex-col items-center justify-start">
        <NavbarBlur />
        <main>
          <motion.section
            ref={welcomeRef}
            id="welcome-section"
            className="flex mt-24 max-sm:mt-20 items-center justify-center"
            variants={sectionVariants}
            initial="hidden"
            animate={welcomeInView ? "visible" : "hidden"}
          >
            <WelcomeSection />
          </motion.section>

          <motion.section
            ref={servicesRef}
            id="services-section"
            variants={sectionVariants}
            initial="hidden"
            animate={servicesInView ? "visible" : "hidden"}
          >
            <BarNames />
            <ServicesSection />
          </motion.section>

          <motion.section
            ref={whyRef}
            id="why-section"
            variants={sectionVariants}
            initial="hidden"
            animate={whyInView ? "visible" : "hidden"}
          >
            <WhySection />
            <AssistantAndPartners />
          </motion.section>

          <motion.section
            ref={peopleSaysRef}
            id="people-says"
            variants={sectionVariants}
            initial="hidden"
            animate={peopleSaysInView ? "visible" : "hidden"}
          >
            <PeopleSays />
          </motion.section>

          <motion.section
            id="logo-area-two"
            className="flex flex-col justify-center items-center max-w-[100vw]"
            variants={sectionVariants}
            initial="hidden"
            animate="visible"
          >
            <PricingPlans />
            <PricingComponent />
          </motion.section>

          <motion.section
            ref={contactRef}
            id="contact-section"
            variants={sectionVariants}
            initial="hidden"
            animate={contactInView ? "visible" : "hidden"}
          >
            <ContactSection />
          </motion.section>
        </main>
      </div>
      <Footer />
    </HelmetProvider>
  );
};

export default App;
