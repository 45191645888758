import React from 'react';

const testimonials = [
  {
    name: 'Mohamed Desoky',
    role: 'Owner of El Nile Group (Agriculture)',
    text: "Circle has revolutionized our business processes. Their tools are incredibly intuitive and have significantly boosted our productivity.",
    imgSrc: 'https://via.placeholder.com/41x41',
    rating: 5,
  },
  {
    name: 'Mohamed Atef',
    role: 'Owner of delta misr (Tires)',
    text: "Thanks to Circle, managing our inventory and customer relations has never been easier. Their platform is a game changer.",
    imgSrc: 'https://via.placeholder.com/41x41',
    rating: 4.5,
  },
  {
    name: 'Kerlos Edward',
    role: 'Owner of Emass Global',
    text: "Circle's technology has taken our operations to the next level. We couldn't be more satisfied with the results.",
    imgSrc: 'https://via.placeholder.com/41x41',
    rating: 5,
  },
  {
    name: 'Ahmed El Corashy',
    role: 'Owner of Support Constructions',
    text: "With Circle, we have streamlined our construction projects and improved communication across teams. It's been a fantastic experience.",
    imgSrc: 'https://via.placeholder.com/41x41',
    rating: 4.5,
  },
  {
    name: 'Fr. Lily',
    role: 'Owner of Sophia Cosmetics',
    text: "Circle has been instrumental in growing our online presence and managing our customer interactions. It's a must-have for any business.",
    imgSrc: 'https://via.placeholder.com/41x41',
    rating: 5,
  },
];

const PeopleSays: React.FC = () => {
  return (
    <div className="w-full py-16 bg-[#6a65ff]/10 flex flex-col justify-start items-center">
      <div className="w-full max-w-screen-lg px-4 text-center">
        <h2 className="text-white text-3xl sm:text-4xl md:text-5xl font-['Luckiest Guy'] leading-snug">
          What people are saying about <span className="text-[#ffffff]">Circle</span>
        </h2>
      </div>
      <div className="w-full max-w-screen-xl mt-12 px-4 overflow-hidden">
        <div className="flex flex-wrap justify-center gap-6 overflow-x-auto no-scrollbar font-['Poppins']">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`w-full max-w-[300px] sm:max-w-[360px] md:max-w-[470px] p-6 bg-[#151925] rounded-lg flex-shrink-0 flex flex-col gap-4 ${
                index === 1 ? 'border-2 border-[#515def]' : ''
              }`}
            >
              <div className="flex items-center gap-4">
                {testimonial.imgSrc ?
                <img
                  className="w-10 h-10 rounded-full"
                  src={testimonial.imgSrc}
                  alt={`${testimonial.name} avatar`}
                />
                 :
                 <div className="w-10 h-10 rounded-full bg-[#515def]"></div>
                }
                <div>
                  <div className="text-white text-base font-medium">{testimonial.name}</div>
                  <div className="text-[#9d9d9d] text-sm">{testimonial.role}</div>
                </div>
              </div>
              <p className="text-[#9d9d9d] text-sm md:text-base">{testimonial.text}</p>
              <div className="flex gap-1">
                {[...Array(Math.floor(testimonial.rating))].map((_, idx) => (
                  <div key={idx} className="w-6 h-6 text-yellow-500">★</div>
                ))}
                {testimonial.rating % 1 !== 0 && (
                  <div className="w-6 h-6 text-yellow-500">☆</div>
                )}
                {[...Array(5 - Math.ceil(testimonial.rating))].map((_, idx) => (
                  <div key={idx} className="w-6 h-6 text-gray-400">★</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PeopleSays;
